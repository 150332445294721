<template>
  <section>
    <Header />
    <section class="dados_cadastrais">
      <HeroHeader :data="header" />
      <div class="container">
        <div class="row py-4 pt-5">
          <div class="col-md-6 d-flex align-items-center pt-3">
            <h5 class="color-text text-uppercase fs-16 f-600">
              <feather class="color-tertiary is-16 mr-1" type="user"></feather>
              Informações Pessoais
            </h5>
          </div>
        </div>
        <transition mode="out-in">
          <div class="row" style="min-height: 40vh">
            <div class="col-md-8">
              <p class="text-dark"></p>
              <form v-on:submit.prevent="updateUser">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="username" class="fs-12 color-text"
                      >Nome Completo</label
                    >
                    <InputText
                      id="username"
                      class="form-input"
                      type="text"
                      v-model="name"
                      required
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="celular" class="fs-12 color-text"
                      >Celular</label
                    >
                    <InputText
                      id="celular"
                      class="form-input"
                      type="text"
                      v-model="celular"
                      v-mask="['(##) ####-####', '(##) #####-####']"
                      required
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="email" class="fs-12 color-text"
                      >E-mail</label
                    >
                    <InputText
                      id="email"
                      class="form-input"
                      type="text"
                      v-model="email"
                      required
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="empresa" class="fs-12 color-text"
                      >Empresa</label
                    >
                    <InputText
                      id="empresa"
                      class="form-input"
                      type="text"
                      v-model="empresa"
                      required
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="cargo" class="fs-12 color-text">Cargo</label>
                    <InputText
                      id="cargo"
                      class="form-input"
                      type="text"
                      v-model="cargo"
                      required
                    />
                  </div>
                </div>
                <div class="form-row">
                  <hr class="col-md-12" />
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="password" class="fs-12 color-text"
                      >Senha atual</label
                    >
                    <InputText
                      id="password"
                      class="form-input"
                      type="text"
                      v-model="password"
                      required
                    />
                  </div>
                  <div class="form-group col-md-6 position-relative">
                    <label for="newPassword" class="fs-12 color-text"
                      >Nova Senha</label
                    >
                    <Password
                    :type="textType"
                    id="newPassword"
                      autocomplete="off"
                      class="form-input"
                      v-model="password_confirmation"
                      required
                      strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
                      weakLabel="Fraca"
                      mediumLabel="Média"
                      strongLabel="Ótimo!"
                      :feedback="true"
                      promptLabel="Insira sua senha"
                    />
                                            <feather
                          class="color-tertiary eye is-16 mr-1"
                          :type="visibility"
                          @click="switchPassword"
                        ></feather>
                      <small>Ao menos 8 caractéres com uma letra maiúscula.</small>
                  </div>
                  <div class="form-group col-md-12">
                    <button
                      type="submit"
                      class="btn btn-success flex-row d-flex justify-content-center align-items-center"
                      style="width: 250px"
                    >
                      <div
                        class="spinner-grow text-light"
                        role="status"
                        v-if="loading"
                      ></div>
                      <span class="fs-14 text-uppercase">Atualizar dados</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </transition>
      </div>
    </section>
    <Footer />
  </section>
</template>

<script>
import HeroHeader from "@/components/HeroHeader.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { mapFields } from "../../helpers";
import Password from "primevue/password";
export default {
  title: "Dados Cadastrais",
  name: "DadosCadastrais",
  components: { HeroHeader, Header, Footer, Password },
  data() {
    return {
      password: "",
      password_confirmation: "",
      loading: false,
      displayModal: false,
            visibility: "eye",
      textType: "password",
      header: {
        title: "Dados Cadastrais",
        description: "acompanhe aqui seus eventos",
        image: "",
      },
    };
  },
  computed: {
    ...mapFields({
      fields: [
        "id",
        "name",
        "email",
        "tipo",
        "cpf",
        "cnpj",
        "nome_fantasia",
        "nome_cert",
        "pronome",
        "empresa",
        "cargo",
        "raz_social",
        "insc_municipal",
        "insc_estadual",
        "cep",
        "rua",
        "num",
        "comp",
        "bairro",
        "cidade",
        "uf",
        "pais",
        "telefone",
        "celular",
        "perfil",
        "nascimento",
        "first_acesso",
        "perfil",
      ],
      base: "user",
      mutation: "UPDATE_USER",
    }),
  },
  methods: {
        switchPassword() {
      this.textType = this.textType == "password" ? "text" : "password";
      this.visibility = this.visibility == "eye" ? "eye-off" : "eye";
    },
    updateUser() {
      this.$store.dispatch("updateUser");
    },
    uploadImage(e) {
      let arquivo = e.target.files || e.dataTransfer.files;
      if (!arquivo.length) {
        return;
      }

      let reader = new FileReader();
      reader.onload = (e) => {
        this.perfil = e.target.result;
        console.log(this.perfil);
      };
      reader.readAsDataURL(arquivo[0]);
    },
  },
  created() {
    this.$store.dispatch("me");
  },
};
</script>

<style lang="scss" scoped>
.as-icon {
  width: calc(100% - 13vw);
  i {
    position: absolute;
    right: 10px;
    top: 20px;
  }
}

.payment-status {
  color: white;
  padding: 2rem 2.7rem;
  border-radius: 5px;
}
.eye {
  position: absolute;
  top: 55px;
  right: 10px;
  z-index: 2;
  cursor: pointer;
}
</style>
