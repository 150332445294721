<template>
<footer class="bg-white mt-5 py-3">
    <div class="container">
        <div class="row">
            <div class="col-md-6  d-flex align-items-center">
                <img src="@/assets/images/logo.svg" alt="Logo" width="100">
            </div>
            <div class="col-md-6 d-flex align-items-center">
                <small class="color-text pr-5 fs-12">Prosci Brasil - todos os direitos reservados</small>
                <small class="color-text fs-12">Desenvolvido por <img src="@/assets/images/codelab.svg" alt="Codelab" class="pl-2 img-fluid">  </small>
            </div>
        </div>
    </div>
</footer>
</template>

<script>
    export default {
        name: 'Footer'
    }
</script>

<style lang="scss" scoped>

</style>